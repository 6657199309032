<template>
    <div>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <ul v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching note</p>
            </div>
        </ul>
        <CCard>
        <CCardHeader>
            <CIcon name="cil-justify-center"/><strong> Note </strong>
        </CCardHeader>
        <CCardBody>
        <ul v-if="!fetching && note==null">
            <p>Note {{ $route.params.noteId }} not found</p>
        </ul>
        <div v-if="note">
            <CRow class="note-row">
              <CCol>
            <div class="d-flex w-100 justify-content-between">
            <div>
                <div class="d-flex w-100 align-items-center">
                <span class="indicator"><CBadge color="info">{{note.noteType}}</CBadge></span>
                </div>
            </div>

            <small>{{ note.createdDate | formatDateAgo }}</small>
            </div>
              </CCol>
            </CRow>

            <CRow class="note-row">
            <CCol>
                {{ note.noteText }}
            </CCol>
            </CRow>

        </div>
        </CCardBody>
        </CCard>
        </CCol>
        </CRow>

        <ul v-if="errors && errors.length">
            <b-list-group-item v-for="error in errors" v-bind:key="error.message" href="javascript:;" class=" flex-column align-items-start py-4 px-4">
            {{error.message}}
            </b-list-group-item>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
export default {
name: 'Note',
components: {
},
  data() {
    return {
      note: null,
      errors: [],
      fetching: false
    }
  },
  methods: {
    fetchNote () {
        console.log('fetch note now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('noteId: ' + this.$route.params.noteId)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/notes/' + this.$route.params.noteId;
        console.log('url: ' + url)
        console.log('fetch note')
        this.fetching = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Note, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch note, response: ' + response)
                        this.note = response.data
                        this.fetching = false;
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('fetch note, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch note, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)
                    })
            }
        })
    }
  },
  // Fetches posts when the component is created.
  mounted() {
        console.log('call fetchNote!!!')
        this.fetchNote()
        console.log('Note:created')
  }

}
</script>

<style>
.note-row {
    padding-bottom: 10px;
}
</style>
